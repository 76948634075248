.tos {
  margin: 80px 0;
  text-align: left;
}
.tos_heading .tos_heading-time {
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 50px;
}
.tos_heading_title{
  font-weight: 700;
}

@media (max-width: 550px) {
  .tos {
    margin: 10px 15px;
  }
  .tos_heading .tos_heading-time {
    margin-bottom: 30px;
  }
}

.tos .each-sec {
  text-align: left;
  margin-bottom: 50px;
}

@media (max-width: 550px) {
  .tos .each-sec {
    font-size: 15px;
  }
}
