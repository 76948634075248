/* --------------------------- */
.menu-btn-anchor {
  text-decoration: none;
}
.menuList {
  display: none;
}
.activeList {
  display: block !important;
}
.chev-rotate {
  transform: rotate(90deg);
}
.page-btn {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgb(175, 175, 175);
  border-radius: 0;
  padding: 16px;
  width: 100%;
  cursor: pointer;
}
.page-btn .wrapper {
  display: flex;
  align-items: center;
  /* padding-top: 10px; */
}
.page-btn ul {
  padding-top: 20px;
  font-size: 15px;
}
.page-btn ul a {
  color: #ffffff;
}
.page-btn ul li:not(:last-child) {
  margin-bottom: 5px;
}
.page-btn .list-q {
  margin-bottom: 8px;
}

.page-btn .chev-right {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  margin-right: 10px;
  transition: transform 0.3s ease-in-out;
  /* background-color: #6ca0b6; */
}

.page-btn .chev-right .chev-icon {
  position: relative;
  height: 100%;
  /* color: white; */
  transition: transform 0.3s ease-in-out;
}
.page-btn:hover .chev-icon {
  /* transform: rotate(90deg); */
  right: initial;
}
.page-btn .page-name {
  font-size: 16px;
  text-align: left;
}

@media (max-width: 550px) {
  .page-btn {
    padding: 10px;
  }
  .page-btn .chev-right {
    height: 20px;
    width: 20px;
    margin-right: 8px;
  }
  .page-btn .page-name {
    font-size: 13px;
  }
}

/* Privacy */
.priv {
  background-color: inherit;
  color: #000000;
}
.priv-chev {
  background-color: #6ca0b6;
  color: #ffffff;
}
.priv-hover {
  background-color: #6ca0b6;
  color: #ffffff;
}
.priv-hover-chev {
  background-color: #ffffff;
  color: #6ca0b6;
}

/* Terms of services */
.terms {
  background-color: inherit;
  color: #000000;
}
.terms-chev {
  background-color: #e9785b;
  color: #ffffff;
}
.terms-hover {
  background-color: #e9785b;
  color: #ffffff;
}
.terms-hover-chev {
  background-color: #ffffff;
  color: #e9785b;
}

/* Disclaimer */
.disc {
  background-color: inherit;
  color: #000000;
}
.disc-chev {
  background-color: #31a38d;
  color: #ffffff;
}
.disc-hover {
  background-color: #31a38d;
  color: #ffffff;
}
.disc-hover-chev {
  background-color: #ffffff;
  color: #31a38d;
}
.chevRotate90 {
  transform: rotate(90deg);
}
