.return-t-btn {
  text-align: left;
}
.return-t {
  text-align: left;
  display: inline-flex;
  gap: 10px;
  margin: 20px 0;
  cursor: pointer;
}
.return-t .return-t_chev-up {
  height: 25px;
  width: 25px;
  background-color: #6ca0b6;
  color: #fff;
  border-radius: 50%;
}
.return-t .chev-up {
  position: relative;
  top: -2px;
}
.return-t .text {
  color: #6ca0b6;
}
.return-t-anchor {
  text-decoration: none;
}
