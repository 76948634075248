.dis {
  text-align: left;
  margin: 50px 0;
}

.dis_heading-time {
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 50px;
}
.dis_heading-h2{
  font-weight: 700;
}

@media (max-width: 550px) {
  .dis {
    margin: 10px 15px;
  }
  .dis_heading-time {
    margin-bottom: 30px;
  }
}

.dis .each-sec {
  text-align: left;
  margin-bottom: 50px;
}

@media (max-width: 550px) {
  .dis .each-sec {
    font-size: 15px;
  }
}
