.goTopHidden {
  display: none;
}
.goTop {
  display: block;
}
.goTop__text {
  position: fixed;
  background: rgba(252, 248, 241, 0.8);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  bottom: 5%;
  right: 5%;
  cursor: pointer;
  text-align: center;
  line-height: 30px;
}
.goTop__text:hover {
  transform: scale(1.1);
}
