/* -------------------------------- */
/* Content Heading */
.content {
  margin: 50px 0;
}

.content .content_heading {
  margin-bottom: 40px;
}
.content .content_heading h2 {
  text-align: left;
  font-weight: 700;
  margin-bottom: 10px;
}
.content .content_heading p {
  text-align: left;
  font-weight: 500;
  font-size: 14px;
}

@media (max-width: 550px) {
  .content {
    margin: 10px 15px;
  }

  .content .content_heading {
    position: relative;
    margin-bottom: 30px;
  }
  .content .content_heading h2 {
    text-align: left;
    font-weight: 400;
    margin-bottom: 5px;
  }
  .content .content_heading p {
    text-align: left;
    font-weight: 500;
    font-size: 12px;
  }
  .content .content_heading .content_heading-right {
    position: fixed;
    right: 10px;
    display: block;
  }
  .content .content_heading .content_heading-right .icon {
    height: 30px;
    margin-top: 2px;
    background-color: #f8f9fa;
  }
}

/* Content intro */
.content .content_intro {
  text-align: left;
  font-size: 18px;
  margin-bottom: 70px;
}
@media (max-width: 550px) {
  .content .content_intro {
    text-align: left;
    font-size: 16px;
    margin-bottom: 40px;
  }
}

/* Summary Of Key Points */
.content .content_key-points {
  text-align: left;
  margin-bottom: 70px;
}
.content .content_key-points h2 {
  font-weight: 400;
  margin-bottom: 30px;
}

@media (max-width: 550px) {
  .content .content_key-points {
    text-align: left;
    margin-bottom: 40px;
    font-size: 15px;
  }
  .content .content_key-points h2 {
    font-weight: 400;
    margin-bottom: 18px;
  }
}

/* Table Of Contents */
.content .each-section {
  text-align: left;
  margin-bottom: 70px;
}

@media (max-width: 550px) {
  .content .each-section {
    font-size: 15px;
  }
}
