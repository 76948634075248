body,
html {
  background-color: #f8f9fa;
  text-align: center;
}

@media (min-width:801px) {
  .main-container {
    height: 100vh;
  }
}
.main-container {
  display: flex;
  flex-direction: column;
}
