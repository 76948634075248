/* utility classes */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.slight-bold {
  font-weight: 500;
}

.heading-h2 {
  font-weight: 400;
  margin-bottom: 30px;
}

.heading-h3 {
  font-weight: 400;
}

.content-link {
  text-decoration: underline;
}

@media (max-width: 550px) {
  .heading-h2 {
    font-weight: 400;
    margin-bottom: 18px;
  }
}

/* ------------------------ */
/* Privacy */

.page-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 17px;
}
.page-links ul li:not(:last-child) {
  margin-bottom: 15px;
}

.page-links .logo {
  margin-top: 30px;
  margin-bottom: 40px;
}

.page-links .logo .menu-icon {
  display: none;
}

.page-links .main {
  width: 1002px;
  display: grid;
  grid-template-columns: 39% auto;
  column-gap: 40px;
}
@media (max-width: 550px) {
  .page-links {
    display: initial;
    justify-content: initial;
    font-size: 15px;
  }
  .page-links ul li:not(:last-child) {
    margin-bottom: 8px;
  }
  .page-links .logo {
    margin: 15px 15px 15px 15px;
    display: flex;
    justify-content: space-between;
  }
  .page-links .logo-img {
    height: 30px;
  }

  .page-links .logo .menu-icon {
    display: block;
    height: 30px;
  }
  .page-links .main {
    width: 100%;
    display: initial;
    grid-template-columns: initial;
    column-gap: initial;
  }
}

/* Sidebar */
.page-links .sidebar {
  text-align: left;
  margin-top: 50px;
  border-right: 1px solid rgb(175, 175, 175);
}
.page-links .sidebar .sidebar_main {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

@media (max-width: 550px) {
  .page-links .sidebar {
    position: absolute;
    top: 64px;
    right: 0;
    width: 100%;
    padding-top: 0;
    margin-top: 0px;
    border-right: initial;
    background-color: #fff;
    transition: all 0.3s ease-in-out;
    z-index: 12;
  }
  .page-links .sidebar .sidebar_main {
    position: -webkit-absolute;
    position: relative;
    top: 0px;
    right: 0;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
}
