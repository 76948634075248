.social-container {
  align-self: center;
  align-items: center;
  padding: 1% 1%;
  padding-left: 5vw;
  padding-right: 5vw;
  padding-top: 20px;
}

@media (min-width:801px) {
  .social-container {
    padding-top: 0;
  }
}

.social-links {
  padding: 1.5vh 6vw;
  text-align: center;
}
@media (max-width: 768px) {
  .social-links {


    
  }
}


.mid-text {
  margin-left: auto;
  margin-right: auto;
  max-width: 40em;
  font-size: 24px;
}

.social-container .social .fa-icon {
  font-size: 2rem;
}

@media screen and (max-width: 768px) {
  .social-container .social .fa-icon {
    font-size: 33px;
  }
}

.social-icon {
  height: 2rem;
  width: 2rem;
  vertical-align: sub;
}

@media (max-width: 600px) {
  .social-icon {
    height: 1.9rem;
    width: 1.9rem;
  }
}

a.social {
  margin: 0 0.8rem;
  transition: transform 250ms;
  display: inline-block;
}

a.social:hover {
  transform: translateY(-10px);
}

a.youtube {
  color: red;
}

a.facebook {
  color: royalblue;
}

a.twitter {
  color: rgb(134, 203, 226);
}

a.insta {
  color: #f63b5e;
}

a.twitch {
  color: purple;
}

a.linkedin {
  color: #006db0;
}

a.tiktok {
  color: #ff0050;
}

a.reddit {
  color: orangered;
}

a.snapchat {
  color: #e6c700;
}

a.pinterest {
  color: red;
}

a.onlyfans {
  color: #f5a623;
}

a.spotify {
  color: #1db954;
}

.logo {
  padding-top: 2rem;
  padding-bottom: 0.50rem;
}